<template>
  <a-modal
    :visible="visible"
    :title="title"
    :closable="closable"
    :mask-closable="closable"
    :confirm-loading="confirmLoading"
    :destroy-on-close="true"
    auto-focus-button="cancel"
    :ok-text="$t('values.yes')"
    :cancel-text="$t('values.no')"
    :after-close="afterClose"
    @ok="handleOk"
    @cancel="handleCancel">
    <p v-if="step===DeleteSteps.CONFIRM">
      {{ $tc('components.description.deleteDataSetModalConfirmationBody', rowCount, { rowCount, dataSet }) }}
    </p>
    <p v-else>
      {{ $t('components.description.deleteDataSetModalDeletingBody', { dataSet }) }}
    </p>
  </a-modal>
</template>

<script>
import flatten from 'lodash/flatten';

const DeleteSteps = {
  CONFIRM: 'confirm',
  DELETE: 'delete'
};

export default {
  name: 'DeleteDataSetModal',
  props: {
    hot: {
      type: Object,
      required: false,
      default: () => undefined
    },
    sourceColumn: {
      type: Number,
      required: true
    },
    visible: {
      type: Boolean,
      default: false
    },
    dataSet: {
      type: String,
      default: ''
    },
    headerConfigs: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      DeleteSteps,
      step: DeleteSteps.CONFIRM
    };
  },
  computed: {
    rowsToDelete() {
      return this?.hot?.getDataAtCol ? this.hot.getDataAtCol(this.sourceColumn).map((row, index) => (row === this.dataSet ? index : false)).filter(row => row !== false) : [];
    },
    rowCount() {
      return this.rowsToDelete.length;
    },
    title() {
      if (this.step === DeleteSteps.CONFIRM) {
        return this.$t('components.description.deleteDataSetModalConfirmationTitle');
      }
      return this.$t('components.description.deleteDataSetModalDeletingTitle');
    },
    closable() {
      return this.step === DeleteSteps.CONFIRM;
    },
    confirmLoading() {
      return this.step === DeleteSteps.DELETE;
    }
  },
  methods: {
    handleOk() {
      if (this.hot) {
        try {
          this.step = DeleteSteps.DELETE;
          const changes = flatten(this.rowsToDelete.map((rowId) => this.headerConfigs
            .map((header, index) => (header?.config?.readOnly ? false : index))
            .filter((indx) => indx !== false)
            .map((index) => [rowId, index, null])));
          this.$emit('set-data-changes', changes);
        } catch (error) {
          this.$message.loading({ content: error.message, duration: 2 });
          this.$emit('close-modal');
        }
      }
    },
    handleCancel() {
      if (this.closable) {
        this.$emit('close-modal');
      }
    },
    afterClose() {
      this.step = DeleteSteps.CONFIRM;
    }
  }
};
</script>
